/* Mixins */
@mixin line-clamp($lineheight, $lines) {
  line-height: $lineheight !important;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin line-clamp-fixed($fontsize, $lineheight, $lines) {
  font-size: $fontsize;
  line-height: $lineheight !important;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  /* IGNORELINE */
  height: $fontsize * $lineheight * $lines; /* Fallback for non-webkit */
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Global */
html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100vh;
  body {
    font-size: $font-size-base;
  }
}

/* Buttons */
%button-styles {
  position: relative;
  display: inline-block;
  text-decoration: none !important;
  border-radius: $btn-border-radius;
  padding: $btn-padding-y #{($btn-padding-x * 3) + 5} $btn-padding-y
    $btn-padding-x;
  line-height: 1.25;
  text-align: left;
  font-family: $font-family-base;
  font-weight: $btn-font-weight;
  transition: all $transition-curve $transition-duration;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: all $transition-curve $transition-duration;
  }
}

/* Homepage sorting fix */
body.homepage .homeFeaturesWrapper {
  display: flex;
  flex-direction: column;
  // Define the number of classes you want to generate
  $number-of-classes: 12;
  // Create a loop to generate the classes
  @for $i from 1 through $number-of-classes {
    .homeBox#{$i} {
      order: #{$i};
    }
  }
}

/* Footer sorting fix */
.pageFooter {
  display: flex;
  flex-wrap: wrap;
  // Define the number of classes you want to generate
  $number-of-classes: 12;
  // Create a loop to generate the classes
  @for $i from 1 through $number-of-classes {
    .footerBox#{$i} {
      order: #{$i};
      margin: 0;
    }
  }
}

/* Header ------------------------------------- */
#pageHeader {
  background-color: #FFFFFF;
  z-index: 11 !important;
  .headerContent {
    width: 97.5%;
    justify-content: space-between;
    .mainLogo {
      flex: 0 0 $logo-width;
      height: $logo-height;
      width: $logo-width;
    }
    > .mainCallToAction {
      flex: 0 0 auto;
      align-items: center;
      display: flex;
      .mainCallToActionButtons {
        display: flex;
        align-items: center;
      }
    }
    // burger menu stylings
    .menuMainAlt {
      padding: 17px 21px;
      font-size: 0;
      border-radius: 360px;
      margin: 0 auto;
      &.active {
        color: $text-colour;
        background: $brand-primary;
        padding: 17px 22px;
      }
      &::before {
        font-size: 18px;
        margin-right: 0;
        width: 100%;
      }
    }
    // search
    .header-search {
      flex: auto;
      margin: 0;
      // starting line
      &:after {
        content: "";
        margin: 0 1rem;
        display: inline-block;
        width: 0.65px;
        height: 35px;
        background: #000000;
      }
      .searchContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      // search input disabled
      input {
        display: none;
      }
      button {
        outline: none;
        background-image: url($assets-path + "search.svg");
        background-size: contain;
        background-repeat: no-repeat;
        &:before {
          display: none;
        }
      }
    }
    .menuMain {
     max-width: calc(370px + 40px - 0px);
    }
  }
    // before burger menu mode is activated
    @media (min-width: #{$nav-breakpoint + 1}) {
      .headerContent {
        .mainLogo {
          order: 1;
        }
        .header-search {
          order: 2;
        }
        #menuMain {
          order: 3;
        }
        ul.topLevel {
          margin-right: 1rem;
          justify-content: flex-end;
          > li:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            opacity: 0;
            height: 0.5rem;
            background: $brand-primary;
            transition: all $transition-duration $transition-curve;
          }
          > li:hover:before {
            width: 100%;
            opacity: 1;
          }
        }
        .subMenu {
          padding: 1rem 0rem;
          a {
            text-decoration-line: underline;
            text-decoration-thickness: 1px;
            text-decoration-color: transparent;
            text-underline-offset: 0.125em;
            transition: all $transition-duration $transition-curve;
            &:hover {
              border-left: 5px solid black;
            }
          }
          &.level3 {
            background: $brand-primary;
          }
        }
      }
    }
    // when burger mode is activated
    @media (max-width: $nav-breakpoint) {
      .headerContent {
        padding: 1rem 0;
      }
      #menuMain .topLevel {
        padding: 0.5rem 0;
      }
      .subMenu.level3 {
        background: $brand-primary;
      }
    }
    @media (max-width: 767px) {
      .headerContent .mainLogo {
        flex: 0 0 $logo-mobile-width;
        height: $logo-mobile-height;
        width: $logo-mobile-width;
      }
      // button stylings (donate specifically)
      [class*="cta-button"] {
        // overriding client stylings
        &.donate {
          color: $brand-tertiary !important;
          background: $brand-secondary !important;
          &:hover {
            color: $white !important;
            background: $brand-primary !important;
          }
        }
        &.cta-button-outline {
          color: $white !important;
          border-color: $white !important;
          background: transparent !important;
          &:hover {
            color: $brand-tertiary !important;
            border-color: $brand-secondary !important;
            background: $brand-secondary !important;
          }
        }
      }
    }
    @media (max-width: 1024px) {
      .cta-button-donate-nav {
        margin-right: 20px;
      }
      .menuMain {
        background-color: $brand-primary;
      }
    }
    @media (max-width: 700px) {
      .headerContent .mainCallToAction {
        .cta-button-apply, .cta-button-donate-nav {
          display: none;
        }
      }
      .menuMain {
        z-index: 3;
        .mainCallToAction {
          > * {
          display: flex;
          }
          .cta-button-apply {
            border-radius: 170px;
            font-weight: 500;
            font-size: 18px;
            line-height: 21.6px;
            background-color: #FFFFFF;
            color: #121B26;
            padding: 15px 30px;
            border: 2px solid #121B26;
            margin-bottom: 1rem;
          }
          .cta-button-donate-nav {
            margin-bottom: 2rem;
          }
          .cta-button-apply, .cta-button-donate-nav {
            max-width: fit-content;
            align-self: center;
          }
        }
      }
    }
}

/* Carousel ----------------------------------- */
.homepage .carouselImageSingle {
  position: relative;
  z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 5%;
        width: 133px;
        height: 133px;
        background: url($assets-path + "Cartwheel_Heart.png") no-repeat center center;
        background-size: contain;
        pointer-events: none;
        z-index: 10;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 15%;
        width: 121px;
        height: 121px;
        background: url($assets-path + "moon-full.png") no-repeat center center;
        background-size: contain;
        pointer-events: none;
        z-index: 10;
      }
  .carouselDetailWrapper {
    .carouselSlideDetail {
      margin: 0 4rem;
      padding: 1rem 2rem 2rem;
      max-width: 535px;
      border-radius: 20px;
      .carouselSlideHeading {
        display: none;
      }
      .carouselSlideSummary {
        font-size: 39px;
        font-weight: 700;
        line-height: 54px;
        .readMore {
          border-radius: 230px;
          font-weight: 500;
          font-size: 18px;
          line-height: 21.6px;
          color: #121b26;
          gap: 10px;
          display: flex;
          width: 35%;
          &::after {
            content: "";
            display: inline-block;
            width: 17px;
            height: 17px;
            background-image: url($assets-path + "dark-arrow.svg");
            background-size: auto;
            background-repeat: no-repeat;
          }
          &:hover {
            background-color: #000000;
            color: #ffffff;
            &::after {
              content: "";
              display: inline-block;
              width: 17px;
              height: 17px;
              background-image: url($assets-path + "white-arrow.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 5%;
      width: 66px;
      height: 66px;
      background: url($assets-path + "Cartwheel_Heart.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 15%;
      width: 60px;
      height: 60px;
      background: url($assets-path + "moon-full.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
      z-index: 10;
    }
    .carouselDetailWrapper .carouselSlideDetail .carouselSlideSummary {
      font-size: 32px;
      font-weight: 700;
      line-height: 39px;
    }
  }
  @media (max-width: 425px) {
    .carouselDetailWrapper .carouselSlideDetail {   
      margin: 0 2rem;
      padding: 1rem;
      max-width: 150px;
      .carouselSlideSummary {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        .readMore {
          font-size: 12px;
          line-height: 10.6px;
          width: 100%;
        }
      }
    }
  }
}

/* hide homeFeatures */
.homeFeatures {
  display: none;
}

/* Sections */
///////////* Latest News Feed */
.homefeaturecategory-latestnewsfeed {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  background-color: $bg-light-2;
  .cta-wrapper {
    display: flex;
    padding: 0;
    width: 85%;
    max-width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 39px;
      font-weight: 700;
      line-height: 54px;
    }
    .cta-button {
      border-radius: 230px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21.6px;
      color: #121b26;
      gap: 10px;
      padding: 15px 35px;
      &::after {
        content: "";
        display: inline-block;
        width: 17px;
        height: 17px;
        background-image: url($assets-path + "dark-arrow.svg");
        background-size: auto;
        background-repeat: no-repeat;
      }
      &:hover {
        background-color: #000000;
        color: #ffffff;
        &::after {
          background-image: url($assets-path + "white-arrow.svg");
        }
      }
    }
  }
  .homeFeedBox3 {
    background-color: transparent;
    padding: 2rem 0;
    margin: 0 auto;
    width: 85%;
    max-width: 100%;
    .feedsTitle {
      display: none;
    }
    .feedList { 
      justify-content: center;
      .feedItem {
        background-color: $brand-tertiary;
        padding: 10px;
        border-radius: 20px;
        border: none;
        min-height: 436.55px;
        [class*="image-link"] {
          background-color: transparent;
        }
        .banner {      
          border-radius: 10px 10px 0 0;
        }
        .home-feed-image-link {
          &:hover {
            background-color: transparent;
          }
          img {
            &:hover {
              background-color: transparent;
            }
          }
        }
        .feedItemDetailsWrapper {
          background-color: transparent;
          .feedTitle {
            margin: 0;
            padding: 0 0 1rem 0;
            a {
              color: #ffffff;
              font-size: 24px;
              line-height: 33px;
              font-weight: 700;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          p {
            display: none;
          }
          .readMore {
            background-color: transparent;
            font-weight: 700;
            font-size: 18px;
            line-height: 21.6px;
            color: #ffffff;
            padding: 0;
            gap: 10px;
            display: flex;
            justify-content: start;
            &::after {
              content: "";
              display: inline-block;
              width: 13px;
              height: 12px;
              background-image: url($assets-path + "white-arrow.svg");
              background-size: cover;
              background-repeat: no-repeat;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .navigator {
      display: none;
    }
  }
}
/* Responsive - Latest News Feed */
@media (max-width: 1440px) {
  .homefeaturecategory-latestnewsfeed .homeFeedBox3 .feedList .feedItem {
    min-height: 365.48px;
  }
}
@media (max-width: 1024px) {
  .homefeaturecategory-latestnewsfeed .homeFeedBox3 .feedList .feedItem {
    min-height: 100%;
  }
  .homefeaturecategory-latestnewsfeed {
    .homeFeedBox3 .feedList .feedItem .feedItemDetailsWrapper .feedTitle {   
      font-size: 0.953rem;
      a {
        font-size: 16px;
        line-height: 20px;
      }
    }
    .homeFeedBox3 .feedList .feedItem .feedItemDetailsWrapper .readMore {
      font-size: 16px;
    }
  }
}
@media (max-width: 425px) {
  .homefeaturecategory-latestnewsfeed {
    .cta-wrapper { 
      flex-direction: column;
      align-items: start;
      h2 {      
        font-size: 39px;
        line-height: 54px;
      }
    }
  }
}

///////////* Action section */
.homefeaturecategory-actioncontainer {
  width: 100%;
  max-width: 100%;
  padding: 5rem 0; 
  margin: 0 auto;
  display: flex;
  background-color: transparent;
  .action-wrapper {
    display: flex;
    width: $container-grid-size;
    max-width: $container-max-width;
    margin: 0 auto;
    place-items: center;
    img {  
      height: 100%;
      max-height: 50%;
    }
    img:nth-of-type(2) {
      margin-left: 2rem;
    }
    ul {
      list-style: none;
      margin: 0 auto;
      width: 85%;
      .cta-button {
        border-radius: 230px;
        font-size: 18px;
        line-height: 21.6px;
        font-weight: 500;
        gap: 10px;
        color: $text-colour;
        padding: 15px 35px;
        &::after {
          content: "";
          display: inline-block;
          width: 17px;
          height: 17px;
          background-image: url($assets-path + "dark-arrow.svg");
          background-size: contain;
          background-repeat: no-repeat;
        }
        &:hover {
          background-color: #000000;
          color: #ffffff;
          &::after {
            background-image: url($assets-path + "white-arrow.svg");
          }
        }
      }
    }
    ul:first-of-type {
      margin-right: 2rem;
    }
  }
}
/* Responsive - Action Section */
@media (max-width: 1024px) {
  .homefeaturecategory-actioncontainer {
    .action-wrapper {
      flex-direction: column;
      img {    
        padding: 2rem 0;
      }
      img:nth-of-type(2) {
        margin: 0;
      }
      ul:first-of-type {
        margin: 0;
      }
    }
  }
}
@media (max-width: 425px) {
  .homefeaturecategory-actioncontainer {
    .action-wrapper {
      ul {
        padding: 0;
      }
    }
  }
}

///////////* Referrer Network */
.homefeaturecategory-referrercontainer {
  width: 100%;
  max-width: 100%;
  padding: 4rem 0; 
  display: flex;
  background-color: transparent;
  background-image: url($assets-path + "referrer-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  .referrer-wrapper {
    gap: 2rem;
    display: flex;
    width: $container-grid-size;
    max-width: $container-max-width;
    margin: 0 auto;
    img {
      background-color: $brand-Quaternary;
      display: flex;
      max-width: 50%;
      flex: 0 0 50%;
      justify-content: center;
      align-items: center;
      padding: 10px;
      width: 100%;
      border-radius: 20px;
      margin: 0 auto;
    }
    ul {
      list-style: none;    
      margin: 0;
      padding: 0;
      align-content: center;
      .cta-button {
        border-radius: 230px;
        font-size: 18px;
        line-height: 21.6px;
        font-weight: 500;
        gap: 10px;
        color: $text-colour;
        padding: 15px 35px;
        &::after {
          content: "";
          display: inline-block;
          width: 17px;
          height: 17px;
          background-image: url($assets-path + "dark-arrow.svg");
          background-size: contain;
          background-repeat: no-repeat;
        }
        &:hover {
          background-color: #000000;
          color: #ffffff;
          &::after {
            background-image: url($assets-path + "white-arrow.svg");
          }
        }
      }
    }
  }
}
/* Responsive - Referrer Network */
@media (max-width: 1024px) {
  .homefeaturecategory-referrercontainer {    
    background-position: right;
    .referrer-wrapper {  
      flex-wrap: wrap;
      gap: 2rem;
      img {
        max-width: 100%;
        flex: 0 0 100%;
        margin: 0 auto;
      }
      ul {    
        margin: 0 auto;
        padding: 0;
      }
    }
  }
}

///////////* Homebox Impact Stats */
.homefeaturecategory-homeboximpactstats {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  .homeImpactWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 85%;
    gap: 2rem;
    img {
      position: relative;
      width: 100%;
      max-width: 800px;
      display: inline-block;
      flex: 1;
      padding: 0;
      margin: 0 auto;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url($assets-path + "Group-147.png") no-repeat center center;
      transform: scale(0.7);
      background-size: contain;
      pointer-events: none;
      max-width: 425px;
    }
    .impact-wrapper {
      padding: 0;
      align-content: center;
      h2 {
        font-size: 39px;
        font-weight: 700;
        line-height: 54px;
        color: $text-colour;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $text-colour;
        width: 85%;
        .cta-button {
          border-radius: 230px;
          font-size: 18px;
          line-height: 21.6px;
          font-weight: 500;
          gap: 10px;
          color: $text-colour;
          margin-top: 1rem;
          padding: 15px 35px;
          &::after {
            content: "";
            display: inline-block;
            width: 17px;
            height: 17px;
            background-image: url($assets-path + "dark-arrow.svg");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &:hover {
            background-color: #000000;
            color: #ffffff;
            &::after {
              content: "";
              display: inline-block;
              width: 17px;
              height: 17px;
              background-image: url($assets-path + "white-arrow.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
      }
      table {
        tbody {
          gap: 2rem;
          tr {
            background-color: #f7cc77;
            border-radius: 20px;
            padding: 1rem;
            margin: 1rem 0;
            td {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: $text-colour;
              h2 {
                font-weight: 700;
                font-size: 45px;
                line-height: 60px;
                color: $text-colour;
              }
            }
          }
        }
      }
    }
  }
}
/* Responsive - Impact Stats */
@media (max-width: 768px) {
  .homefeaturecategory-homeboximpactstats {
    .homeImpactWrapper {    
      flex-direction: column;
      img {
        max-width: 50%;
        flex: 0 0 50%;
      }
      &::after {
        top: -25%;
        left: 25%;
        max-width: 50%;
      }
      .impact-wrapper table tbody tr td h2 {
        font-size: 38px;
      }
    }
  }
}
@media (max-width: 425px) {
  .homefeaturecategory-homeboximpactstats {
    .homeImpactWrapper {
      display: grid;
      img {
        max-width: 100%;
        flex: 0 0 100%;
      }
      &::after {
        top: -32%;
        left: 0;
        max-width: 100%;
      }
      .impact-wrapper {
        p {
          width: 100%;
        }
        table tbody {      
          gap: 2px;
        }
      }
    }
  }
}
@media (max-width: 375px) {
  .homefeaturecategory-homeboximpactstats .homeImpactWrapper {
    &::after {
      top: -34%;
    }
  }
}
@media (max-width: 320px) {
  .homefeaturecategory-homeboximpactstats .homeImpactWrapper {
    &::after {
      top: -36%;
    }
  }
}

///////////* Dream Stories */
.homefeaturecategory-dreamstoriestagline {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 5rem 0;
  background-color: transparent;
  background-image: url($assets-path + "stories-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  .storysection {
    display: flex;
    width: 85%;
    max-width: 100%;
    margin: 0 auto;
    flex-direction: row-reverse;
    .tagline-wrapper {
      flex: 0 0 33.3333%;
      max-width: 33.33333%;
      padding: 0;
      align-content: center;
      justify-items: center;
      h2 {
        position: relative;
        width: 85%;
        max-width: 100%;
        font-weight: 700;
        font-size: 31.25px;
        line-height: 45px;
        color: $text-colour;
        &::before {
          content: "";
          position: absolute;
          top: -40%;
          left: 0;
          width: 162px;
          height: 53px;
          background-image: url($assets-path + "quote-pink.svg");
          background-size: contain;
          background-repeat: no-repeat;
          z-index: 10;
        }
      }
    }
    .homefeaturecategory-dreamstoriesfeed {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 66.6666%;
      flex: 0 0 66.6666%;
      margin: 0 auto;
      .cta-wrapper {
        display: flex;
        padding: 0;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-size: 39px;
          font-weight: 700;
          line-height: 54px;
        }
        .cta-button {
          border-radius: 230px;
          font-weight: 500;
          font-size: 18px;
          line-height: 21.6px;
          color: #121b26;
          gap: 10px;
          padding: 15px 35px;
          &::after {
            content: "";
            display: inline-block;
            width: 17px;
            height: 17px;
            background-image: url($assets-path + "dark-arrow.svg");
            background-size: auto;
            background-repeat: no-repeat;
          }
          &:hover {
            background-color: #000000;
            color: #ffffff;
            &::after {
              background-image: url($assets-path + "white-arrow.svg");
            }
          }
        }
      }
      .homeFeedBox2 {
        background-color: transparent;
        padding: 2rem 0 0;
        .feedsTitle {
          display: none;
        }
        .feedList {
          position: relative;
          overflow: hidden;
          display: block;
          margin: 0;
          padding: 0;
          .feedItem {
            background-color: $brand-primary;
            padding: 10px;
            gap: 15px;
            border-radius: 20px;
            height: auto;
            border: none;
            [class*="image-link"] {
              background-color: transparent;
            }
            .banner {
              border-radius: 10px 10px 0 0;
              &:hover {
                background-color: transparent;
              }
              img {
                &:hover {
                  background-color: transparent;
                }
              }
            }
            .feedItemDetailsWrapper {
              padding: 1rem 2rem;
              background-color: transparent;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .feedTitle {
                margin: 0;
                padding: 0 0 1rem 0;
                a {
                  color: $text-colour;
                  font-size: 25px;
                  line-height: 33px;
                  font-weight: 700;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
              p {
                color: $text-colour;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* Limit to 4 rows */
                -webkit-box-orient: vertical;
                overflow: hidden;
                max-height: calc(1.2em * 4);
              }
              .readMore {
                padding: 10px 0 0 0;
                text-align: center;
                font-size: 20px;
                font-weight: 700;
                line-height: 33px;
                color: $text-colour;
                display: inline-flex;
                &::after {
                  content: "";
                  display: flex;
                  width: 17px;
                  height: 17px;
                  background-image: url($assets-path + "dark-arrow.svg");
                  background-size: auto;
                  background-repeat: no-repeat;
                  align-self: center;
                  margin-left: 10px;
                }
                &:hover {
                  color: #FFFFFF;
                  background-color: transparent;
                  &::after {
                    background-image: url($assets-path + "white-arrow.svg");
                  }
                }
              }
            }
          }
        }
        // navigator
        .navigator {
          max-width: 1600px;
          margin: 0 auto;
          padding-top: 2rem;
          // wrapper box
          .slider-box {
            gap: 2rem;
            display: flex;
            align-items: center;
          }
          // text label
          .slider-label {
            display: none;
            margin-bottom: 0;
          }
          // arrows and dots
          .slider-nav {
            gap: 1.5rem;
            display: flex;
            align-items: center;
            .slick-arrow {
              width: 2rem;
              height: 2rem;
              margin: 0;
              padding: 0;
              outline: none;
              border-radius: 50%;
              background-image: url($assets-path + "slider-right.svg");
              background-size: contain;
              background-repeat: no-repeat;
              transform-origin: center;
              display: flex;
              &.prev-slide {
                transform: scaleX(-1);
              }
              &.next-slide {
                transform: none;
              }
              i {
                display: none;
              }
              &:hover {
                background-image: url($assets-path + "Group-189.png");
              }
            }
            .dot-wrapper ul {
              list-style: none;
              padding: 0;
              margin: 0;
              gap: 0.75rem;
              display: flex;
              align-items: center;
              li {
                display: flex;
              }
              button {
                width: 10px;
                height: 10px;
                margin: 0;
                padding: 0;
                opacity: 0.2;
                outline: none;
                font-size: 0;
                border-radius: 50%;
                background: #000000;
                &:hover {
                  opacity: 1;
                  background: $brand-secondary;
                }
              }
              .slick-active button {
                opacity: 1;
                background: #000000;
              }
            }
          }
          // cta button not needed
          .button {
            display: none;
          }
        }
      }
    }
  }
}
/* Responsive - Dream Stories*/
@media (max-width: 1024px) {
  .homefeaturecategory-dreamstoriestagline {
    .storysection {  
      padding: 5rem 0 0 0;
      flex-direction: column;
      .tagline-wrapper {
        max-width: 100%;
        padding-bottom: 2rem;
        h2 {
          &::before {
            top: -65%;
          }
        }
      }
      .homefeaturecategory-dreamstoriesfeed {
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 425px) {
  .homefeaturecategory-dreamstoriestagline {
    .storysection .tagline-wrapper h2 {
      &::before {
        top: -30%;
      }
    }
    .homefeaturecategory-dreamstoriesfeed {
      .cta-wrapper {
        flex-wrap: wrap;
        gap: 1rem;
      }
      .homeFeedBox2 .feedList {
        display: flex;
        .feedItem {  
          margin: 0 auto;
        }
      }
    }
  }
}

///////////* Discover our next events */
.homefeaturecategory-oureventsfeed {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  .cta-wrapper {
    display: flex;
    padding: 0;
    width: 85%;
    max-width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 39px;
      font-weight: 700;
      line-height: 54px;
    }
    .cta-button {
      border-radius: 230px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21.6px;
      color: #121b26;
      gap: 10px;
      padding: 15px 35px;
      &::after {
        content: "";
        display: inline-block;
        width: 17px;
        height: 17px;
        background-image: url($assets-path + "dark-arrow.svg");
        background-size: auto;
        background-repeat: no-repeat;
      }
      &:hover {
        background-color: #000000;
        color: #ffffff;
        &::after {
          background-image: url($assets-path + "white-arrow.svg");
        }
      }
    }
  }
  .homeFeedBox1 {
    background-color: transparent;
    padding: 2rem 0;
    margin: 0 auto;
    width: 85%;
    max-width: 100%;
    .feedsTitle {
      display: none;
    }
    .feedList { 
      justify-content: center;
      display: block;
      .feedItem {
        background-color: $brand-tertiary;
        padding: 10px;
        border-radius: 20px;
        border: none;
        [class*="image-link"] {
          background-color: transparent;
        }
        .banner {      
          border-radius: 10px 10px 0 0;
        }
        .home-feed-image-link {
          &:hover {
            background-color: transparent;
          }
          img {
            &:hover {
              background-color: transparent;
            }
          }
        }
        .feedItemDetailsWrapper {
          background-color: transparent;
          .feedTitle {
            margin: 0;
            padding: 0 0 2rem 0;
            a {
              color: #ffffff;
              font-size: 25px;
              line-height: 33px;
              font-weight: 700;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          p {
            display: none;
          }
          .readMore {
            background-color: transparent;
            display: inline-flex;
            font-weight: 700;
            font-size: 18px;
            line-height: 21.6px;
            color: #ffffff;
            padding: 0;
            gap: 10px;
            &::after {
              content: "";
              display: inline-block;
              width: 13px;
              height: 12px;
              background-image: url($assets-path + "white-arrow.svg");
              background-size: cover;
              background-repeat: no-repeat;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    // navigator
    .navigator {
      width: 75%;
      max-width: 1600px;
      margin: 0 auto;
      padding-top: 2rem;
      justify-items: center;
      // wrapper box
      .slider-box {
        gap: 2rem;
        display: flex;
        align-items: center;
      }
      // text label
      .slider-label {
        display: none;
        margin-bottom: 0;
      }
      // arrows and dots
      .slider-nav {
        gap: 1.5rem;
        display: flex;
        align-items: center;
        .slick-arrow {
          width: 2rem;
          height: 2rem;
          margin: 0;
          padding: 0;
          outline: none;
          border-radius: 50%;
          background-image: url($assets-path + "slider-right.svg");
          background-size: contain;
          background-repeat: no-repeat;
          transform-origin: center;
          display: flex;
          &.prev-slide {
            transform: scaleX(-1);
          }
          &.next-slide {
            transform: none;
          }
          i {
            display: none;
          }
          &:hover {
            background-image: url($assets-path + "Group-189.png");
          }
        }
        .dot-wrapper ul {
          list-style: none;
          padding: 0;
          margin: 0;
          gap: 0.75rem;
          display: flex;
          align-items: center;
          li {
            display: flex;
          }
          button {
            width: 10px;
            height: 10px;
            margin: 0;
            padding: 0;
            opacity: 0.2;
            outline: none;
            font-size: 0;
            border-radius: 50%;
            background: #000000;
            &:hover {
              opacity: 1;
              background: $brand-secondary;
            }
          }
          .slick-active button {
            opacity: 1;
            background: #000000;
          }
        }
      }
      // cta button not needed
      .button {
        display: none;
      }
    }
  }
}
/* Responsive - Discover our next events */
@media (max-width: 1024px) {
  .homefeaturecategory-oureventsfeed .homeFeedBox1 .feedList .feedItem .feedItemDetailsWrapper .feedTitle a {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .homefeaturecategory-oureventsfeed {
    .cta-wrapper {
      h2 {      
        font-size: 32px;
      }
    }
  }
}
@media (max-width: 425px) {
  .homefeaturecategory-oureventsfeed {
    .cta-wrapper {
      flex-wrap: wrap;
      h2 {      
        font-size: 28px;
        line-height: 44px;
      }
    }
    .homeFeedBox1 {
      padding: 0;
      .navigator {
        padding: 0;
      }
    }
  }
}

///////////* Donate Now */
.homefeaturecategory-donatebanner {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 5rem 0;
  margin: 0 auto;
  background-color: transparent;
  background-image: url($assets-path + "donate-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  .donatesection {
    display: flex;
    width: 85%;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 5rem;
    .donatebanner {
      display: flex;
      width: 100%;
      max-width: 55%;
      margin: 0 auto;
      padding: 0;
      flex: 0 0 55%;
      .banner-wrapper {
        align-content: center;
        h3 {
          font-weight: 700;
          font-size: 31.25px;
          line-height: 45px;
          color: $text-colour;
          width: 90%;
        }
      }
    }
    .donatepanel {
      display: flex;
      width: $container-grid-size;
      max-width: $container-max-width;
      margin: 0 auto;
      padding: 2rem 0 0;
      gap: 10px;      
      .homefeaturecategory-donatepanel {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 85%;
        max-width: 100%;
        padding: 0;
        margin: 0 auto;
        .cta-wrapper {
          display: flex;
          flex-direction: column;
          background-color: #f7cc77;
          padding: 2rem 2rem 0;
          width: 100%;
          max-width: 1600px;
          margin: 0 auto;
          justify-content: space-between;
          border-radius: 20px;
          h3 {
            font-size: 31.25px;
            font-weight: 700;
            line-height: 45px;
            color: $text-colour;
          }
          .quickGivingPanel {
            background-color: transparent;
            text-align: left;
            padding: 0;
            .selectDonationAmount {
              .donationAmount {
                background-color: #ffffff;
                width: 100px;
                min-width: auto;
                align-items: center;
                border-radius: 10px;
                &:hover {
                  border: 1px solid #000000;
                }
              }
              .donationAmountOther {
                width: 135px;
                align-items: center;
                label {
                  width: 135px;
                  .donationAmountFigure {
                    justify-self: center;
                  }
                }
              }
              .donationSelected {
                background-color: #ffffff;
                border: 1px solid #000000;
              }
              .donateBtn {
                border-radius: 230px;
                font-weight: 500;
                font-size: 18px;
                line-height: 21.6px;
                color: #121b26;
                margin: 1rem 0;
                gap: 10px;
                padding: 10px;
                width: 30%;
                display: flex;
                &::after {
                  content: "";
                  display: inline-block;
                  width: 15px;
                  height: 14px;
                  background-image: url($assets-path + "dark-arrow.svg");
                  background-size: auto;
                  background-repeat: no-repeat;
                }
                &:hover {
                  background-color: #000000;
                  color: #ffffff;
                  &::after {
                    content: "";
                    display: inline-block;
                    width: 15px;
                    height: 14px;
                    background-image: url($assets-path + "white-arrow.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                  }
                }
              }
            }
          }
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 190px;
      height: 184px;
      background-image: url($assets-path + "moon.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
/* Responsive Donate Now */
@media (max-width: 1440px) {
  .homefeaturecategory-donatebanner {
    .donatesection {
      .donatepanel {
        .homefeaturecategory-donatepanel {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .homefeaturecategory-donatebanner .donatesection .donatepanel .homefeaturecategory-donatepanel .cta-wrapper .quickGivingPanel .selectDonationAmount .donationAmount {
    width: 100%;
    margin-left: 0;
  }
  .homefeaturecategory-donatebanner .donatesection .donatepanel .homefeaturecategory-donatepanel .cta-wrapper .quickGivingPanel .selectDonationAmount .donateBtn {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .homefeaturecategory-donatebanner {
    display: grid;
    background-color: #edefea;
    background-image: none;
    padding: 2rem 0;
    margin: 0 auto;
    .donatesection {
      flex-wrap: wrap;
      .donatebanner {
        display: grid;
        padding: 0 0 1rem;
        flex: 0 0 85%;
        max-width: 85%;
        .banner-wrapper {
          padding: 0;
          h3 {
            font-size: 25px;
            line-height: 32px;
          }
        }
        .homefeaturecategory-donatepanel
          .cta-wrapper
          .quickGivingPanel
          .selectDonationAmount
          .donationAmount {
          width: 100%;
        }
        .homefeaturecategory-donatepanel
          .cta-wrapper
          .quickGivingPanel
          .selectDonationAmount
          .donationAmountOther {
          width: 100%;
        }
      }
      .donatepanel {      
        width: 100%;
        padding: 0;
        .homefeaturecategory-donatepanel .cta-wrapper .quickGivingPanel .selectDonationAmount {
          .donationAmount {
            width: 100%;
          }
          .donateBtn {
            width: 100%;
          }
        }
      }
      &::after {
        display: none;
      }
    }
  }
}

///////////* Help us */
.homefeaturecategory-helpuscontainer {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  h2 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: center;
    font-size: 39px;
    font-weight: 700;
    line-height: 54px;
  }
  .wrapper {
    width: 85%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;    
    li {
      background-color: $brand-primary;
      display: flex;
      justify-content: center;
    }
    li:first-child {
      border-radius: 20px 20px 0 0;
      padding: 10px 10px 0;
    }
    li:last-child {
      border-radius: 0 0 20px 20px;
      padding: 10px;
    }
    p {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      padding: 0 0 10px 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 33px;
      text-align: left;
      color: $text-colour;
      display: inline-flex;
      &::after {
        content: "";
        display: flex;
        width: 17px;
        height: 17px;
        background-image: url($assets-path + "dark-arrow.svg");
        background-size: auto;
        background-repeat: no-repeat;
        align-self: center;
        margin-left: 10px;
      }
      &:hover {
        color: #FFFFFF;
        a { 
          color: #FFFFFF;
        }
        &::after {
          background-image: url($assets-path + "white-arrow.svg");
        }
      }
    }
    a { 
      color: $text-colour;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: -10%;
    right: 0;
    width: 135px;
    height: 179px;
    background-image: url($assets-path + "star-cut.svg");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }
}
/* Responsive - Help us */
@media (max-width: 1024px) {
  .homefeaturecategory-helpuscontainer {
    .wrapper p {
      font-size: 16px;
      line-height: normal;
    }
  }
}
@media (max-width: 768px) {
  .homefeaturecategory-helpuscontainer {
    .wrapper {
      flex-wrap: wrap;
      ul {
        padding: 0;
        margin-bottom: 3rem;
      }
    }
  }
}

///////////* Discover Dreams */
.homefeaturecategory-discovercontainer {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  background-image: url($assets-path + "dreams-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  h2 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: center;
    font-size: 39px;
    font-weight: 700;
    line-height: 54px;
  }
  .wrapper {
    width: 85%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;    
    li {
      background-color: $brand-tertiary;
      display: flex;
      justify-content: center;
    }
    li:first-child {
      border-radius: 20px 20px 0 0;
      padding: 10px 10px 0;
    }
    li:last-child {
      border-radius: 0 0 20px 20px;
      padding: 10px;
    }
    p {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      padding: 0 0 10px 0;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 33px;
      text-align: left;
      color: #FFFFFF;
      display: inline-flex;
      &::after {
        content: "";
        display: flex;
        width: 17px;
        height: 17px;
        background-image: url($assets-path + "white-arrow.svg");
        background-size: auto;
        background-repeat: no-repeat;
        align-self: center;
        margin-left: 10px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    a { 
      color: #FFFFFF;
    }
  }
}
/* Responsive - Discover Dreams */
@media (max-width: 1024px) {
  .homefeaturecategory-discovercontainer {
    .wrapper p {
      font-size: 16px;
      line-height: normal;
    }
  }
}
@media (max-width: 768px) {
  .homefeaturecategory-discovercontainer {
    background-color: $bg-light;
    background-size: auto;
    .wrapper {
      flex-wrap: wrap;
      ul {
        padding: 0;
        margin-bottom: 3rem;
      }
    }
  }
}

///////////* Starter */
.homefeaturecategory-startercontainer {
  position: relative;
  padding: 3rem 0;
  display: flex;
  align-items: center;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .starter-container {
    display: flex;
    margin: 0 auto;
    gap: 2rem;
    .Placeholder {
      position: relative;
      z-index: 2;
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      text-align: end;
      flex: 0 0 50%;
      iframe {
        background-color: $orange;
        padding: 10px;
        border-radius: 20px;
        height: 368px;
        width: 80%;
      }
    }
    ul {
      position: relative;
      list-style: none;
      padding: 0;
      align-content: center;
      //margin: 0 auto;
      //max-height: 276px;

      h2 {
        width: 85%;
        font-size: 39px;
        font-weight: 700;
        line-height: 54px;
        padding-bottom: 1rem;
      }
      p {
        width: 85%;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
/* Responsive - Starter */
@media (max-width: 1024px) {
  .homefeaturecategory-startercontainer {
    gap: 2rem;
    align-items: center;
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .starter-container {
    flex-wrap: wrap;
    .Placeholder {
      flex: 1 !important;
      text-align: center !important;
    }
    ul li {
      h2, p {
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
@media (max-width: 425px) {
  .homefeaturecategory-startercontainer {
    .Placeholder {
      max-width: 100%;
      flex: 0 0 85%;
    }
    ul {
      max-height: 100%;
    }
    h2 {
      font-size: 34px;
      line-height: 45px;
    }
  }
}

////////////* Multiple sections change color for <a> */

/* Footer ------------------------------------- */
#pageFooterWrapper {
  overflow: hidden;
  // General footer
  .pageFooter {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    background-image: url($assets-path + "footer-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    .mainNewsletter {
      position: relative;
      padding: 0;
      width: $container-grid-size;
      max-width: $container-max-width;
      display: flex;
      flex-wrap: nowrap;
      border-bottom: 1px solid rgba(18, 27, 38, 0.2);
      .NewsletterTagline {
        h1 {
          color: #121b26;
          font-size: 39px;
          line-height: 54px;
          font-weight: 700;
          margin: 0 0 1rem 0;
        }
        p {
          color: #121b26;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
        }
      }
      .NewsletterSign-up {
        color: #000000;
        font-size: 13.6px;
        line-height: 20px;
        font-weight: 500;
        #mc_embed_signup_scroll {
          grid-template-columns: repeat(3, 1fr);
          display: grid;
          gap: 0.5rem 1rem;
          input {
            border: 1px solid #121b2638;
            border-radius: 10px;
          }
          .mc-field-group {
            label {
              display: flex;
            }
            input {
              width: 100%;
            }
          }
          .button {
            background-color: $brand-primary;
            border-radius: 230px;
            border: none;
            color: #121b26;
            font-size: 14px;
            font-weight: 500;
            line-height: 21.6px;
            gap: 10px;
            &::after {
              content: "";
              display: inline-block;
              width: 15px;
              height: 14px;
              background-image: url($assets-path + "dark-arrow.svg");
              background-size: auto;
              background-repeat: no-repeat;
            }
            &:hover {
              background-color: #000000;
              color: #ffffff;
              &::after {
                content: "";
                display: inline-block;
                width: 15px;
                height: 14px;
                background-image: url($assets-path + "white-arrow.svg");
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
    }
    .mainFooterSection {
      position: relative;
      padding: 1.5rem 0;
      margin: 3rem auto;
      width: $container-grid-size;
      max-width: $container-max-width;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto auto;
      gap: 20px;
      // Align Footer boxes
      .footerBox3 {
        grid-column: 1;
        grid-row: 1;
      } /* FooterQuickLinks */
      .footerBox4 {
        grid-column: 2;
        grid-row: 1;
      } /* FooterGetintouch */
      .footerBox5 {
        grid-column: 3;
        grid-row: 1;
      } /* FooterSocials */
      .footerBox6 {
        grid-column: 3;
        grid-row: 1;
      } /* FooterLogos */
      h4 {
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        padding-bottom: 1rem;
        color: #121b26;
      }
      .footerLinks,
      .footerget {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #121b26;
        text-decoration: none;
        a {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #121b26;
          text-decoration: none;
        }
      }
      .FooterQuicklinks {
        .footerLinks {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          max-width: 300px;
        }
        .footerLinks li:nth-child(1) {
          grid-column: 1;
          grid-row: 1;
        }
        .footerLinks li:nth-child(2) {
          grid-column: 1;
          grid-row: 2;
        }
        .footerLinks li:nth-child(3) {
          grid-column: 1;
          grid-row: 3;
        }
        .footerLinks li:nth-child(4) {
          grid-column: 1;
          grid-row: 4;
        }
        .footerLinks li:nth-child(5) {
          grid-column: 2;
          grid-row: 1;
        }
        .footerLinks li:nth-child(6) {
          grid-column: 2;
          grid-row: 2;
        }
      }
      .FooterSocials {
        .socialIcons {
          a {
            background-color: transparent;
            &::before {
              color: #000000;
              font-size: 25px;
            }
          }
        }
        .socialIcons li:hover {
          transform: translateY(-5px);
          border-bottom: 2px solid #000;
        }
      }
      .FooterLogos {
        display: grid;
        grid-template-columns: repeat(1, 2fr);
        max-width: 400px;
        padding: 3rem 0 0;
        margin: 3rem 0 0;
        .footerLogos {
          .mediaImage {
            padding: 1rem 4rem 1rem 0;
          }
        }
      }
    }
  }
  .FooterCredits {
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    background-color: #121b26;
    padding: 5px;
    bottom: 0;
    p {
      color: #ffffff;
      margin: 1rem;
    }
  }
  #mce-responses {
    display: none;
  }
  // responsives - general footer
  @media (max-width: 1440px) {
    .pageFooter {
      .mainFooterSection {
        padding: 0;
        margin: 0 auto;
        h4 {
          padding: 1rem 0 0;
        }
        .footerBox6 {
          padding: 2rem 0 0;
          margin: 2rem 0 0;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .pageFooter {
      .mainFooterSection {
        padding: 0;
        margin: 0 auto;
        gap: 10px;
        h4 {
          padding: 1rem 0 0;
        }
        .footerBox6 {
          padding: 2rem 0 0;
          grid-template-columns: max-content;
          width: 100%;
          margin: 0 auto;
          padding-left: 5rem;
          grid-column: 1;
          grid-row: 2;
        }
      }
      .mainNewsletter .NewsletterSign-up #mc_embed_signup_scroll {
        grid-template-columns: none;
      }
    }
  }
  @media (max-width: 768px) {
    .pageFooter {
      justify-content: stretch;
      .FooterLinks {
        flex: 0 0 calc(50% - 1rem);
      }
      .FooterUseful,
      .socials-logos-wrapper {
        flex: 100%;
      }
      .FooterLogos ul img {
        max-width: 90px;
        max-height: 50px;
      }
      .mainFooterSection {
        grid-template-columns: repeat(2, 2fr);
        .footerBox5 {
          grid-column: 1;
          grid-row: 2;
        }
        .footerBox6 {
          grid-template-columns: repeat(1, 2fr);
          grid-column: 2;
          grid-row: 2;
          padding-left: 0;
          .mediaImage {
            padding: 1rem 2rem 1rem 0 !important;
          }
        }
    }
  }
  .FooterCredits p {
    font-size: 14px;
  }
  }
  @media (max-width: 425px) {
    .pageFooter {
      background-color: #edefea;
      background-image: none;
      .footerBox {
        display: flex;
        flex-direction: column;
        .footerBox1 {
          padding: 1rem 0 0;
        }
        .footerBox2 {
          padding: 0 0 1rem;
        }
        .footerBox6 {  
          padding: 0;
        }
      }
    }
    .FooterCredits p {
      font-size: 12px;
    }
  }
}

.cookie-preference-container {
  position: relative;
}

//////////////////////////////////////////////////
/* Carousel Layout ---------------------------- */
body:not(.homepage) .carouselUsed {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, $bg-light 0%, transparent 100%);
  }
  // carousel wrapper
  .carousel {
    position: relative;
    margin: 0 auto;
    width: $container-grid-size;
    max-width: $container-max-width;
    // background color
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      height: 100%;
      max-width: 1920px;
      width: 100%;
      transform: translateX(-50%);
      background-color: rgba($brand-secondary, 0.05);
      border-radius: 20px;
    }
  }
  // carousel container
  #carouselSlides {
    padding: 3rem 0;
    .carouselSlide {
      gap: 3rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: row-reverse;
      // stops it jumping when switching slides
      &.hidden {
        visibility: hidden;
        height: 0;
      }
      // slide image
      .carouselSlideTitle {
        flex: 1;
      }
      // slide content
      .carouselDetailWrapper {
        flex: 1;
        .carouselSlideDetail {
          padding: 0;
          background-color: transparent;
          // summary
          .carouselSlideSummary {
            font-size: $font-size-large;
            color: $black;
          }
          // read more
          .readMore {
            @extend %button-styles;
            margin-top: 1rem;
            display: block;
            width: fit-content;
            color: $btn-text-colour;
            background: $btn-background-colour;
            &:after {
              background-image: url($assets-path + "arrow-purple.svg");
            }
            &:hover,
            &:focus,
            &:active {
              color: $text-colour;
              background: $brand-primary;
              &:after {
                background-image: url($assets-path + "arrow-orange.svg");
              }
            }
          }
        }
      }
    }
  }
  // responsives - sub carousel
  @media (max-width: 991px) {
    #carouselSlides .carouselSlide {
      flex-direction: column-reverse;
      .carouselSlideTitle,
      .carouselDetailWrapper {
        flex: 100%;
      }
    }
  }
  // for title area if present
  & + .contentBlockWrapper .headerText {
    position: relative;
    margin: 2rem auto;
    padding: 3rem;
    max-width: $post-max-width;
    background: $bg-light-2;
    border-radius: 20px;
    // hide it for now
    display: none;
  }
}

/* Has Banner Layout -------------------------- */
body:not(.homepage).page-has-banner,
body:not(.listing-with-feature).page-has-banner {
  // top parent level header
  .pageWrapper .headerWrapper:not(.currentProjectsHeader) {
    // image banner
    .carousel.carouselImageSingle {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, $bg-light 0%, transparent 100%);
      }
      .carouselSlides {
        margin: 0 auto;
        width: 100%;
        .carouselSlide {
          height: 350px;
          @media (max-width: 767px) {
            height: 200px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }
        }
        .carouselSlideTitle {
          height: 100%;
        }
        .carouselSlideDetail {
          display: none;
        }
      }
    }
    // content banner
    .headerText {
      position: relative;
      margin: 2rem auto 0;
      z-index: 1;
      padding: 2rem 3rem;
      background: $brand-tertiary;
      color: #ffffff;
      border-radius: 20px;
      max-width: $post-max-width;
      width: $container-grid-size;
      .title {
        margin-bottom: 0;
      }
    }
  }
  // event page
  .contentBlock > .eventWidgetsWrapper {
    position: relative;
    padding: 2rem 3rem;
    max-width: $post-max-width;
    margin: 0 auto 6rem;
    .eventDetails {
      flex-direction: row;
    }
    .eventWhenWrapper,
    .eventWhereWrapper {
      flex: 1;
      margin-bottom: 0;
    }
    // responsives - event page
    @media (max-width: 991px) {
      .eventDetails {
        flex-direction: column;
        gap: 1rem;
      }
    }
    @media (max-width: 767px) {
      padding-left: 7.5%;
      padding-right: 7.5%;
    }
  }
  // appeal post page
  .appealPostPage .contentBlockWrapper {
    max-width: $container-max-width;
    width: $container-grid-size;
    margin: 0 auto;
    &.appealActionsWrapper {
      display: none;
    }
    .contentBlock > .content:not(.listContent) {
      position: relative;
      margin: 2rem auto;
      max-width: $post-max-width;
      padding: 3rem;
      background: $bg-light-2;
      border-radius: 20px;
      // responsives - post content
      @media (max-width: 767px) {
        padding-left: 7.5%;
        padding-right: 7.5%;
      }
    }
  }
  // listing page
  .listing {
    .headerWrapper {
      .headerText {
        .content {
          p a {
            color: $text-colour;
            img {          
              border-radius: 20px;
            }
          }
          .Placeholder {
            iframe {
              border-radius: 20px;
            }
          }
        }
      }
    }
    .listContent {
      margin-top: 2rem;
      .listedItem {
        .listedPostText {
          border-radius: 20px;
        }
      }
    }
  }

  // post content
  .headerWrapper + .contentBlockWrapper {
    max-width: $container-max-width;
    width: $container-grid-size;
    margin: 0 auto;
    .contentBlock > .content:not(.listContent) {
      position: relative;
      margin: 2rem auto;
      max-width: $post-max-width;
      background: $bg-light-2;
      border-radius: 20px;
      padding: 6rem 3rem 3rem;
      // on listing page
      &.listingIntro {
        padding-top: 3rem;
        font-size: $font-size-large;
        > *:last-child {
          margin-bottom: 0;
        }
      }
      // footer dates
      &:not(.eventContent) .postFooterWrapper {
        margin: 0;
        .postFooter > *:not(.shareWrapper) {
          display: block;
          > *:not(.publishDatesWrapper) {
            display: none;
          }
          .publishDatesWrapper {
            position: absolute;
            top: 3rem;
            left: 3rem;
            margin: 0;
            &:before {
              content: "\f073";
              font-family: "Font Awesome 5 Free";
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-size: 18px;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              color: $brand-Quaternary;
              margin-right: 0.5rem;
            }
            strong {
              display: none;
            }
            time {
              color: #000000;
              font-size: $font-size-large;
              font-weight: $font-weight-bold;
            }
            @media (max-width: 767px) {
              left: 7.5%;
            }
          }
        }
      }
      // responsives - post content
      @media (max-width: 767px) {
        padding-left: 7.5%;
        padding-right: 7.5%;
      }
    }
    .content:not(.listContent) .button {
      @extend %button-styles;
      color: $btn-text-colour;
      background: $btn-background-colour;
      &:after {
        background-image: url($assets-path + "dark-arrow.svg");
      }
      &:hover,
      &:focus,
      &:active {
        color: $text-colour;
        background: $brand-primary;
        &:after {
          background-image: url($assets-path + "white-arrow.svg");
        }
      }
    }
  }
}
// Subsite version
body.subsite.page-has-banner {
  // top level header
  .headerWrapperSubsite {
    // image banner
    .carousel.carouselImageSingle {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, $bg-light 0%, transparent 100%);
      }
      .carouselSlides {
        margin: 0 auto;
        width: 100%;
        .carouselSlide {
          height: 350px;
          @media (max-width: 767px) {
            height: 200px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }
        }
        .carouselSlideTitle {
          height: 100%;
        }
        .carouselSlideDetail {
          display: none;
        }
      }
    }
    // content banner
    .headerText {
      position: relative;
      margin: 2rem auto 0;
      z-index: 1;
      padding: 2rem 3rem;
      background: $bg-light-2;
      border-radius: 20px;
      max-width: $post-max-width;
      width: $container-grid-size;
      .title {
        margin-bottom: 0;
      }
    }
  }
  // sub title + body
  .headerTextSubsite,
  .subsiteBody,
  #menuSub {
    font-size: $font-size-large;
    max-width: $post-max-width;
    width: $container-grid-size;
    margin: 2rem auto;
    padding: 3rem;
    border-radius: 20px;
    background: $bg-light;
    .subsiteTitle {
      margin-bottom: 0;
    }
    .topLevel {
      margin-bottom: 0;
    }
    // responsives
    @media (max-width: 767px) {
      padding-left: 7.5%;
      padding-right: 7.5%;
    }
  }
}

/* No Banner Layout --------------------------- */
body:not(.homepage).page-no-banner,
body:not(.listing-with-feature).page-no-banner {
  // Hide image
  .carousel {
    display: none;
  }
  // title + breadcrumb section
  .pageWrapper .headerWrapper {
    position: relative;
    background-color: $brand-primary;
    > .headerText {
      position: relative;
      z-index: 1;
      padding: 5rem 0;
      margin: 0 auto;
      width: $container-grid-size;
      max-width: $container-max-width;
    }
  }
  // listing intro description
  .listing .contentBlockWrapper:not(.accordianBody) {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    width: $container-grid-size;
    max-width: $container-max-width;
    .listingIntro {
      margin: 2rem auto;
      position: relative;
      padding: 3rem;
      background: $bg-light-2;
      border-radius: 20px;
      max-width: $post-max-width;
      font-size: $font-size-large;
      > *:last-child {
        margin-bottom: 0;
      }
      .headerWrapper {
        display: none;
      }
      .contentBlockWrapper {
        margin-left: 0;
      }
      @media (max-width: 767px) {
        padding-left: 7.5%;
        padding-right: 7.5%;
      }
      .button {
        @extend %button-styles;
        color: $btn-text-colour;
        background: $btn-background-colour;
        &:after {
          background-image: url($assets-path + "arrow-purple.svg");
        }
        &:hover,
        &:focus,
        &:active {
          color: $text-colour;
          background: $brand-primary;
          &:after {
            background-image: url($assets-path + "arrow-orange.svg");
          }
        }
      }
    }
  }
  // listing - donors list
  .listing .donorsListWrapper {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: $container-grid-size;
    max-width: $container-max-width;
    .listingIntro {
      position: relative;
      font-size: $font-size-large;
      @media (max-width: 767px) {
        font-size: $font-size-base;
      }
      > * {
        // max-width: 930px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .donorsList {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }
  }
  // if on post page without banner
  .noBanner {
    .contentBlockWrapper:not(.appealActionsWrapper) {
      margin: 0 auto;
      width: $container-grid-size;
      .contentBlock > .content {
        position: relative;
        margin: 2rem auto;
        padding: 3rem;
        border-radius: 20px;
        background: $bg-light;
        max-width: $post-max-width;
        // responsives - post content
        @media (max-width: 767px) {
          padding-left: 7.5%;
          padding-right: 7.5%;
        }
      }
      .contentBlock > .eventWidgetsWrapper {
        position: relative;
        margin: 0 auto 2rem;
        max-width: $post-max-width;
        padding: 2rem 3rem;
        // event post page
        .eventDetails {
          flex-direction: row;
        }
        .eventWhenWrapper,
        .eventWhereWrapper {
          flex: 1;
          margin-bottom: 0;
        }
        // responsives - event post page
        @media (max-width: 991px) {
          .eventDetails {
            flex-direction: column;
            gap: 1rem;
          }
        }
        @media (max-width: 767px) {
          padding-left: 7.5%;
          padding-right: 7.5%;
        }
      }
      .content:not(.listContent) .button {
        @extend %button-styles;
        color: $btn-text-colour;
        background: $btn-background-colour;
        &:after {
          background-image: url($assets-path + "arrow-purple.svg");
        }
        &:hover,
        &:focus,
        &:active {
          color: $text-colour;
          background: $brand-primary;
          &:after {
            background-image: url($assets-path + "arrow-orange.svg");
          }
        }
      }
    }
  }
  .appealPostPage .contentBlockWrapper {
    max-width: $container-max-width;
    width: $container-grid-size;
    margin: 0 auto;
    &.appealActionsWrapper {
      display: none;
    }
    .contentBlock > .content:not(.listContent) {
      position: relative;
      margin: 2rem auto;
      max-width: $post-max-width;
      padding: 3rem;
      background: $bg-light;
      border-radius: 20px;
      // responsives - post content
      @media (max-width: 767px) {
        padding-left: 7.5%;
        padding-right: 7.5%;
      }
    }
  }
}

/* Listing Items */
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  // on hover
  &:hover {
    border-color: $brand-secondary;
  }
  // item image
  [class*="image-link"] {
    position: relative;
  }
  // item content
  [class^="listed"][class$="Text"] {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 20px 20px !important;
    // title text
    h2 {
      margin-bottom: 0.5rem;
      line-height: 30px;
    }
    .postSummary {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    // read more
    .readMore {
      @extend %button-styles;
      display: block;
      width: fit-content;
      margin-top: 1.5rem;
      background: transparent !important;
      color: $btn-text-colour;
      font-size: $font-size-base;
      padding: 5px 50px 5px 0px;
      &:after {
        width: 24px;
        height: 24px;
        background-image: url($assets-path + "dark-arrow.svg");
      }
    }
  }
  // footer
  .postFooterWrapper {
    position: absolute;
    top: -14.5px;
    left: 2rem;
    width: auto;
    display: block;
    .postFooter {
      // general
      > *:not(.button) {
        opacity: 1;
      }
      // hide locations
      .locationDetails {
        display: none !important;
      }
      // display event start date
      .associatedStartDate {
        display: inline-block !important;
        font-size: $font-size-small;
        padding: 0.25rem 0.5rem;
        background: $bg-light-2;
        border-radius: 5px;
        margin: 0;
        &:before {
          display: none;
        }
      }
    }
  }
}

/* Associated Feeds */
.postFeeds {
  .associatedBox,
  .associatedAppeal {
    display: none;
  }
}
.associated:not(.associatedBox) {
  position: relative;
  padding-top: 3rem;
  &:before {
    content: "";
    position: absolute;
    transform: translateX(-50%);
    z-index: -1;
    left: 50%;
    top: 0;
    width: 100%;
    height: calc(100% - 2rem);
    background: rgba($brand-tertiary, 0.05);
    border-radius: 20px;
  }
  // big title
  h2 {
    text-align: center;
    font-size: $font-size-h2;
  }
  // listing
  .associatedListing {
    gap: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  // list item
  li:not(.sidebarCard) {
    flex: 0 0 calc(33.3333% - 30px);
    margin-bottom: 0;
    padding: 0;
    background: $white;
    border: 1px solid $border-colour;
    transition: border $transition-curve $transition-duration;
    // on hover
    &:hover {
      border-color: $brand-secondary;
    }
    // image
    .associated-image-link {
      position: relative;
      margin-bottom: 0;
      .banner {
        width: 100%;
      }
    }
    // title text
    h3 {
      font-size: $font-size-h4;
      padding: 1rem;
    }
    // footer
    .postFooterWrapper {
      display: none;
    }
  }
  @media (max-width: 991px) {
    li:not(.sidebarCard) {
      flex: 0 0 calc(50% - 30px);
    }
  }
  @media (max-width: 767px) {
    li:not(.sidebarCard) {
      flex: 100%;
    }
  }
}
.pagesPostBody .pageWrapper .post .postFeedWrapper {
  &::before {
    content: "";
    position: absolute;
    bottom: 29%;
    right: 1%;
    width: 143px;
    height: 450px;
    background: url($assets-path + "group-faq2.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    z-index: -1;
  }
  .associated {
    width: 100%;
    padding: 6rem 0;
    margin: 0 auto;
    .associatedListing {
      width: 85%;
      margin: 0 auto;
    }
  }
}

/* Breadcrumbs */
.headerWrapper {
  color: $text-colour;
}
.breadcrumbList {
  color: $text-colour;
  align-items: center;
  .breadcrumbItem {
    margin-right: 0.2em;
    a {
      font-size: 13.6px;
      line-height: 20px;
    }
  }
  .breadcrumbItem:before {
    content: "/";
    margin-right: 0.2em;
    color: $text-colour;
    font-size: $font-size-base;
  }
  .breadcrumbItem {
    a {
      color: $text-colour;
    }
    &:last-of-type a {
      font-weight: $font-weight-bold;
    }
  }
  // responsive
  @media (max-width: 767px) {
    gap: 0.5rem;
    .breadcrumbItem {
      flex: 100%;
    }
  }
}

/* General Post Content */
.post {
  .postContent {
    font-size: $font-size-large;
    ul {
      li {
        a {
          color: $text-colour;
          font-weight: 700;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    p {
      font-size: $font-size-large;
      img {
        border-radius: 20px;
      }
      a {
        color: $text-colour; 
        font-weight: 700;
      }
      .cta-button {
        border-radius: 120px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21.6px;
        background-color: #00C0AC;
        color: #000000;
        padding: 15px 30px;	
        margin-right: 20px;
        &:hover {
          background-color: #000000;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    h2, h3 {
      a {
        color: $text-colour;
      }
      img {
        border-radius: 20px;
      }
    }
    h4 {
      img {
        border-radius: 20px;
      }
    }
    .Placeholder {
      iframe {
        border-radius: 20px;
      }
    }
    .quickGivingPanel .button {
      display: inline-flex;
    }
  }
}


/* Share This Page */
.shareWrapper {
  max-width: $post-max-width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > strong {
    margin-bottom: 0;
    color: $text-colour;
    font-size: $font-size-large;
  }
  .shareList {
    a {
      background-color: transparent;
      &::before {
        color: #01A392;
        font-size: 25px;
      }
    }
    li:hover {
      transform: translateY(-5px);
      border-bottom: 2px solid #000;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

/* FAQ */
body[class*="faqs"] .faqListingPage .contentBlock {
  .listContent {
    gap: 20px;
    position: relative;
    margin: 0 auto 2rem;
    max-width: $post-max-width;
    width: $container-grid-size;
    .listedFaq.accordianOpen .accordianHeader {
      border-radius: 10px 10px 0 0;
    }
    .accordianHeader {
      margin-bottom: 0;
      border-radius: 10px;
      transition: all $transition-curve $transition-duration;
      a {
        color: $brand-primary;
        font-weight: $font-weight-bold;
      }
      .postSummary {
        transition: all $transition-curve $transition-duration;
      }
      &:hover {
        background: $brand-primary;
        a {
          color: $white;
        }
        .postSummary {
          color: $white;
        }
      }
      .buttonIcon {
        width: 24px;
        height: 24px;
        background-image: url($assets-path + "faq-down.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        &:before {
          display: none;
        }
      }
    }
    .accordianBody {
      margin: 0;
      padding: 0;
      background: rgba($bg-light, 0.05);
      border-radius: 0 0 5px 5px;
      .contentBlock {
        margin-bottom: 0;
        .content {
          padding: 2rem;
          margin-bottom: 0;
          font-size: $font-size-large;
        }
      }
    }
    .mediaImage {
      height: auto;
    }
  }
}

.post .contentBlock {
  display: flex;
  flex-direction: column;
  .postAside {
    width: 100%;
    padding-left: 0;
    height: 100%;
    .content {
      .associated {
        padding: 5rem;
        a {
          color: $text-colour;
        }
        p {
          color: $text-colour;
        }
      }
    }
  }
}

/* Donate Page */
.donationForm {
  margin-top: 2rem;
}
#donation-form-root .react-tabs__tab--selected {
  background-color: $brand-primary !important;
  border-color: $brand-primary !important;
}


// Contact us page
body.contact .post {
  .headerWrapper {
    position: relative;
    // linear gradient
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, $bg-light 0%, transparent 100%);
    }
    // background color
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      max-width: 1920px;
      width: 100%;
      transform: translateX(-50%);
      background-color: rgba($brand-secondary, 0.05);
      border-radius: 20px;
    }
    .headerText {
      position: relative;
      z-index: 1;
      padding: 5rem 0;
      margin: 0 auto;
      width: $container-grid-size;
      max-width: $container-max-width;
    }
  }
  .postContent.contactUsPage {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: $container-grid-size;
    max-width: $post-max-width;
    background: $bg-light-2;
    border-radius: 20px;
    padding: 3rem;
    // responsives
    @media (max-width: 767px) {
      padding-left: 7.5%;
      padding-right: 7.5%;
    }
    .button {
      @extend %button-styles;
      color: $btn-text-colour;
      background: $btn-background-colour;
      &:after {
        background-image: url($assets-path + "arrow-purple.svg");
      }
      &:hover,
      &:focus,
      &:active {
        color: $text-colour;
        background: $brand-primary;
        &:after {
          background-image: url($assets-path + "arrow-orange.svg");
        }
      }
    }
  }
}

// search page
body:not(.homepage).page-no-banner .searchListingPage {
  .headerWrapper {
    &:before,
    &:after {
      display: none;
    }
    > .headerText {
      padding: 3rem 0 0;
    }
  }
  .searchListingIntroWrapper {
    margin-top: 2rem;
    width: $container-grid-size;
  }
}

// check out page
body.checkout .contentBlockWrapper {
  padding-top: 3rem;
}

// 404 page
.container404 {
  margin-top: 10rem;
}

// page has banner
.mixedModuleList {
  .pageWrapper {
    position: relative;
    .headerWrapper .carousel::after {
      content: "";
      position: absolute;
      bottom: -504px;
      left: 1%;
      width: 198px;
      height: 504px;
      background: url($assets-path + "post-group1.png") no-repeat center center;
      background-size: contain;
      pointer-events: none;
    }
    .post .contentBlockWrapper .contentBlock {
      .content  {
        background-color: $bg-light-2;
      }
      .postFeedWrapper {
        width: 100%;
        margin: 0 auto;     
      }
    }
  }
}
.associated:not(.associatedBox) .associated-image-link {
  display: flex !important;
}
.associated:not(.associatedBox) li:not(.sidebarCard) h3, .associated:not(.associatedBox) li:not(.sidebarCard) .basket .total, .basket .associated:not(.associatedBox) li:not(.sidebarCard) .total {
  background-color: $brand-tertiary !important;
  border-radius: 0 0 20px 20px;
  padding: 0 1rem 2rem;
  font-size: 22px;
  a {  
    color: #FFFFFF !important;
  }
}
.associated:not(.associatedBox) li:not(.sidebarCard) .associated-image-link {
  padding: 1rem;
  border-radius: 20px 20px 0 0;
  background-color: $brand-tertiary;
  img {
    border-radius: 20px 20px 0 0;
  }
}
body:not(.homepage).page-no-banner .noBanner .contentBlockWrapper:not(.appealActionsWrapper) .contentBlock > .content, body:not(.listing-with-feature).page-no-banner .noBanner .contentBlockWrapper:not(.appealActionsWrapper) .contentBlock > .content {
  background-color: $bg-light-2 !important;
  color: $text-colour !important;
  p {
    a {
      color: $text-colour; 
      font-weight: 700;
    }
    .cta-button {
      border-radius: 120px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21.6px;
      background-color: #00C0AC;
      color: #000000;
      padding: 15px 30px;	
      margin-right: 20px;
      &:hover {
        background-color: #000000;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
  h2 {
    a {
    color: $text-colour; 
    }
  }  
}

// Post page
.pagesPostBody {
  .pageWrapper {
    .post {
      position: relative;
      .headerWrapper .carousel::after {
        content: "";
        position: absolute;
        bottom: -504px;;
        left: 1%;
        width: 198px;
        height: 504px;
        background: url($assets-path + "post-group1.png") no-repeat center center;
        background-size: contain;
        pointer-events: none;
      }
      .postFeedWrapper {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.button, .cta-button {
  border-radius: 120px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.6px;
  background-color: #00C0AC;
  color: #000000;
  padding: 15px 30px;	
  margin-right: 20px;
  &:hover {
    background-color: #000000;
    color: #ffffff;
    text-decoration: none;
  }
}

// Events pages
.eventsBody {
  .carouselImageSingle::after, &::before {
    display: none;
  }
  .button {
    border-radius: 120px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
  	background-color: #00C0AC;
    color: #000000;
    padding: 15px 30px;	
    margin-right: 20px;
    &:hover {
      background-color: #000000;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

// Category Filter
.categoryFilterWrapper {
  position: relative;
  margin-top: 5rem;
  place-self: center;
  .groupedFilters {
    border-radius: 100px;
    border: 2px solid #000000;
    padding: 13px 30px;
    &:hover {
      background-color: #000000;
      color: #FFFFFF;      
    }
  }
  h2 {
    position: absolute;
    top: -28px;
    left: 1.75rem;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    &:after {
      content: " by";
    }
  }
  .categoryFilterContent {
    padding: 1rem 1.75rem;
    background-color: #FFFFFF;
    [type="checkbox"] {
      outline: none;
      appearance: none;  
    }
    h2 {  
      text-align: center;
    }
  }
} 

// events
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  border: none;
  .listed-post-image-link {
    padding: 1rem;
    border-radius: 20px 20px 0 0;
    background-color: $brand-tertiary;
    img {
      border-radius: 20px 20px 0 0;
    }
  }
  .listedPostText {
    background-color: $brand-tertiary !important;
    border-radius: 0 0 20px 20px;
    h2 {
      a {
        color: #FFFFFF !important;
      }
    }
    p {
      color: #FFFFFF !important;
    }
  }
  .postFooterWrapper {
    display: none !important;
  }
  [class^="listed"][class$="Text"] .readMore {
  border-radius: 230px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.6px;
  color: #ffffff;
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  width: 29%;
  &::after {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    background-image: url($assets-path + "white-arrow.svg");
    background-size: auto;
    background-repeat: no-repeat;
  }
  &:hover {
    background-color: transparent;
    color: $text-colour;
    &::after {
      content: "";
      display: inline-block;
      width: 17px;
      height: 17px;
      background-image: url($assets-path + "dark-arrow.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
}

// Hover over cards internal pages
.associated:not(.associatedBox) li:not(.sidebarCard) {
  border: none;
}
.associated:not(.associatedBox) li:not(.sidebarCard):hover {
  border-color: transparent;
}

/* Blockquote */
blockquote {
  background: #f8faf6;
  padding: 2rem;
  border-radius: 15px;
  font-size: 25px;
  font-weight: 700;
  color: $text-colour;
  text-align: left;
  border: 1rem solid #EDEFEA;
  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 46.36px;
    height: 39px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:before {
    top: -10%;
    left: 3%;
    background-image: url($assets-path + "quote1.svg");
  }
  &:after {
    bottom: -9%;
    right: 3%;
    background-image: url($assets-path + "quote2.svg");
  }
  @media (max-width: 1024px) {
    &:before {
      top: -7%;
    }
    &:after {
      bottom: -7%;
    }
  }
  @media (max-width: 768px) {
    &:before {
      top: -5%;
    }
    &:after {
      bottom: -5%;
    }    
  }
  @media (max-width: 425px) {
    &:before {
      top: -3%;
    }
    &:after {
      bottom: -3%;
    }    
  }
  @media (max-width: 320px) {
    &:before {
      top: -2%;
    }
    &:after {
      bottom: -2%;
    }        
  }
  p {
    margin-bottom: 0;
  }
  // responsives
  @media (max-width: 767px) {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
} 