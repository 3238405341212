$platform-name: "dreamscometrueredesign";
$assets-path: "../assets/";

// Fonts
@font-face {
  font-family: "Figtree";
  src: url($assets-path + "Figtree-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url($assets-path + "PPNeueMontreal-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url($assets-path + "Figtree-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// Brand Colours
$brand-primary: #00c0ac;
$brand-secondary: #e35205;
$brand-tertiary: #708de0;
$brand-Quaternary: #ff9ed6;

$border-colour: #e6e6e6;
$body-colour: #292b2c;

//// Background Palette
$bg-light: #edefea;
$bg-light-2: #f8f9fd;
$white: #ffffff;
$black: #000000;
$bg-black: #151515;
$orange: #F7CC77;

$text-colour: #121b26;
$text-links: #C724B1;

// Typography
$font-family-base: "Figtree", sans-serif;

$font-size-base: 16px;
$font-size-large: 1.25rem;
$font-size-small: 0.875rem;

$line-height-base: 1.5;
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$headings-font-weight: 900;
$displays-font-weight: 900;

$font-size-h1: 3.052rem;
$font-size-h2: 2.441rem;
$font-size-h3: 1.953rem;
$font-size-h4: 1.563rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;

$font-mobile-h1: 38.22px;
$font-mobile-h2: 31.85px;
$font-mobile-h3: 26.54px;
$font-mobile-h4: 22.12px;
$font-mobile-h5: 18.43px;

$font-display-1: 7.45rem;
$font-display-2: 5.96rem;
$font-display-3: 4.768rem;
$font-display-4: 3.815rem;

$mobile-display-1: 72px;
$mobile-display-2: 51.2px;
$mobile-display-3: 36px;
$mobile-display-4: 25.6px;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Layout
$post-max-width: 1000px;
$container-max-width: 1600px;
$container-grid-size: 85%;
$site-bleed: 0px;

// Logo
$logo: "colour-logo.svg";
$logo-width: 255.52px;
$logo-height: 90px;
$logo-mobile-width: 178.73px;
$logo-mobile-height: 60px;

// Menu admin
$menu-admin-enabled: false;

// Header content
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;

// Navigation
$nav-background-colour: $bg-light;
$nav-breakpoint: 1329px;
$nav-background-colour: transparent;

// Main call to action
$cta-btn-gap: 0;
$cta-btn-breakpoint: 767px;

// Nav normal
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-mobile-background-colour: transparent;
$nav-normal-mobile-top-level-item-colour: white;

// Top level items
$nav-top-level-item-padding: 2.125rem 1rem;
$nav-top-level-item-colour: $text-colour;
$nav-top-level-item-font-size: 18px;
$nav-top-level-item-hover-colour: $brand-primary;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: true;

// Submenus
$nav-submenu-width: 240px;
$nav-submenu-background-colour: $brand-primary;
$nav-submenu-border-radius: 0;
$nav-submenu-item-colour: $text-colour;
$nav-submenu-item-font-size: 18px;
$nav-submenu-item-hover-colour: #FFFFFF;
$nav-submenu-item-hover-background-colour: none;
$nav-submenu-chevrons-enabled: true;

// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-mobile-background-colour: #000000;
$nav-normal-mobile-top-level-item-colour: $white;
$nav-normal-mobile-submenu-item-colour: $white;
$nav-normal-mobile-submenu-background-colour: $brand-primary;

// Search
$header-search-margin-right: 0;
$header-search-breakpoint: 0;

// Search - button
$header-search-button-width: 24px;
$header-search-button-height: 24px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 24px;
$header-search-button-icon-colour: $brand-secondary;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-details-style: overlay; // overlay, adjacent or below
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-position-x: left; // left, center, right
$carousel-details-margin-x: 0;
$carousel-details-background-colour: $bg-light;
$carousel-details-border-radius: 5px;
$carousel-breakpoint: 0;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-colour: $text-colour;
$carousel-read-more-background-colour: $brand-primary;
$carousel-read-more-colour: $text-colour;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: false;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: $brand-primary;
$newsletter-heading-font-size: $font-size-h2;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-button-height: 48px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $brand-primary;
$newsletter-button-colour: $white;

// Footer
$footer-colour: $text-colour;
$footer-link-colour: $text-colour;
$footer-link-hover-colour: $text-colour;
$footer-background-colour: transparent;
$footer-padding-y: 0;

// Footer admin links=
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: false;

// Impact stats
$impact-stats-padding-y: 0;
$impact-stats-background-colour: transparent;
$impact-stats-colour: $text-colour;
$impact-stats-breakpoint: 0; // When do you want to break the overall layout from row to column?

// Blockquote
$blockquote-background-colour: transparent;
$blockquote-text-align: left; // left, center, right
$blockquote-font-size: $font-size-h2; // Em unit will cascade to children
$blockquote-line-height: 45px;
$blockquote-padding: 0 0 0 5rem;
$blockquote-colour: $text-colour;

// FAQs
$faq-item-header-background-colour: $bg-light;
$faq-item-header-padding: 1.5rem;
$faq-item-header-font-size: $font-size-large;
$faq-item-btn-background-colour: $brand-tertiary;
$faq-item-btn-chevron-colour: $brand-primary;
$faq-item-hover-btn-background-colour: $brand-tertiary;
$faq-item-hover-btn-chevron-colour: $brand-primary;


// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: false; // Even this should be true.. is not working. SO we stick with the most-read until then.
$associated-most-read-enabled: true;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 3;
$associated-item-image-enabled: true;
$associated-list-heading-font-size: $font-size-h4;
$associated-item-heading-colour: #FFFFFF;
$associated-item-summary-enabled: false;
$associated-item-border: false;
